import React, { useState } from 'react'
import './App.css'

  function App() {
    // Creating a custom hook
    const [name, setName] = useState('Apple Computers Inc.')
    const [tag, setTag] = useState('We build computers in our garage.')
    return (
      <div className='App Colors'>
        <header className='App-Section'>
          <h1>tidy.bio</h1>
          <h4>Always up-to-date</h4>
          <p>You evolve and adapt quickly, but your media doesn't.</p>
          <p>Create, add, and edit to your tidy bio as you evolve, and all your profiles, documents, and sites update.</p>
          <p>Keep track of past messaging, copy, and assets in version control for your team to collaborate.</p>
        </header>
        <div className='App-Section'>
          <div className='App-Flex'>
            <div className='App-Demo'>
              <div className='App-Demo-Tall'>
                <div className='App-Demo-Header'>
                  <div className='App-Demo-Header-Button' />
                  <p className='App-Demo-Header-Button-X'>x</p>
                  <div className='App-Demo-Header-URL'>tidy.bio/apple</div>
                  <div className='App-Demo-Page'>
                    <div className='App-Demo-Page-Line noSelect'>
                      <p className='App-Demo-Page-Line-Title'>Name</p>
                      <input className='App-Demo-Page-Line-Input'
                        value={name}
                        onChange={e => setName(e.target.value)}
                        placeholder='What is your name?'
                      />
                    </div>
                    <div className='App-Demo-Page-Line noSelect'>
                      <p className='App-Demo-Page-Line-Title'>tag</p>
                      <input className='App-Demo-Page-Line-Input'
                        value={tag}
                        onChange={e => setTag(e.target.value)}
                        placeholder='What is your name?'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='App-Demo'>
            <div className='App-Demo-Short'>
              <div className='App-Demo-Header'>
                <div className='App-Demo-Header-Button' />
                <p className='App-Demo-Header-Button-X'>x</p>
                <div className='App-Demo-Header-URL'>www.apple.com</div>
                <div className='App-Demo-Page'>
                  <div className='App-Demo-Page-Line noSelect'>
                    <h4 className='App-Demo-Page-Line-Header'>
                      {name}
                    </h4>
                  </div>
                  <div className='App-Demo-Page-Line noSelect'>
                    <p className='App-Demo-Page-Line-Tag'>
                      {tag}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='App-Demo-Short'>
              <div className='App-Demo-Header'>
                <div className='App-Demo-Header-Button' />
                <p className='App-Demo-Header-Button-X'>x</p>
                <div className='App-Demo-Header-URL'>www.linkedin.com/company/apple</div>
                <div className='App-Demo-Page'>
                  <div className='App-Demo-Page-Line noSelect'>
                    <h4 className='App-Demo-Page-Line-Header'>
                      {name}
                    </h4>
                  </div>
                  <div className='App-Demo-Page-Line noSelect'>
                    <p className='App-Demo-Page-Line-Tag'>
                      {tag}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='App-Demo-Short'>
                <div className='App-Demo-Page'>
                  <div className='App-Demo-Page-Line noSelect'>
                    <h4 className='App-Demo-Page-Line-Header'>
                      {name}
                    </h4>
                  </div>
                  <div className='App-Demo-Page-Line noSelect'>
                    <p className='App-Demo-Page-Line-Tag'>
                      {tag}
                    </p>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className='App-Section'>
          <h2>How it works</h2>
          <h4>Commit your bio into your profile in the cloud, then all platfroms will be updated.</h4>
          <p>Record or rewind any change to your story to keep you and your team in sync.</p>
          <p>Spend more time on creating and less on conformaty, with tidy.bio all your messaging is in harmony.</p>
        </div>
        <div className='App-Section'>
          <h2>Future features</h2>
          <h4>Coordinate your busness development team to create new materials with a roadmap.</h4>
          <h4>Serve different versions customized to different audiences.</h4>
          <h4>Incorporate user feedback and engagment metrics to craft a company that ressonates.</h4>
        </div>
      </div>
    )
}

export default App
